@font-face {
  font-family: "Univers Condensed";
  src: url("./assets/fonts/UNIVERSCONDENSED.TTF");
  font-weight: bold;
}

@font-face {
  font-family: "Univers EW";
  src: url(./assets/fonts/Univers_EW_Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Univers Extended";
  src: url(./assets/fonts/Univers_Extended_Medium.ttf);
}

@font-face {
  font-family: "Univers_Ext";
  src: url(./assets/fonts/Univers-BoldExt.ttf);
}

body .template-one-embed.book-container {
  overflow-y: hidden !important;
}

@media only screen and (max-width: 1024px) {
  body .template-one-embed.book-container {
    height: auto !important;
  }
}

:root {
  --mcolor: #6754e2;
  --scolor: #00c99c;
  --mcolor-dark: #4433ae;
  --mcolor-light: rgb(103, 84, 227, 0.3);
  --hfont: #263b5e;
  --bfont: #6a7695;
}

.skin_color {
  color: var(--mcolor) !important;
}

body {
  font-family: "Univers Condensed" !important;
  background-color: #f2faff !important;
  color: var(--bfont);
  font-size: 17px;
}

.btn {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25) !important;
  font-size: 17px !important;
  font-family: inherit !important;
  letter-spacing: 0.5px;
  padding: 14px 30px !important;

  &.btn-md {
    padding: 7px 20px !important;
  }

  &.btn-lg {
    font-size: 22px !important;
    @media only screen and (min-width: 1600px) {
      font-size: 25px !important;
    }
    @media only screen and (max-width: 991px) {
      font-size: 20px !important;
    }
  }

  @media only screen and (max-width: 1599px) {
    font-size: 0.855rem !important;
  }
}

.hover-a-color:hover {
  color: var(--mcolor) !important;
}

.mcolor {
  color: var(--mcolor);
}

.fs-16 {
  font-size: 16px !important;
}

.btn-primary {
  background-color: var(--mcolor) !important;
  border-color: var(--mcolor) !important;
}

.btn-primary:hover {
  background-color: var(--mcolor-dark) !important;
  border-color: var(--mcolor-dark) !important;
}

.main-content {
  position: relative;
  min-height: 100vh;
  display: flex;

  position: relative;

  &:before {
    content: "";
    position: fixed;
    background-image: url(../src/assets/images/banner-shape.svg);
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .banner-shape {
    position: fixed;
    top: 0;
    z-index: -1;

    &.banner-shape-top {
      left: 50%;
      transform: translateX(-50%);
    }

    &.banner-shape-corner {
      right: 0;
    }

    &.shape-dot {
      top: 50%;
      left: 50%;
    }

    &.shape-triangel {
      right: 5%;
      top: 25%;
    }

    &.shape-dot-yellow {
      left: 14%;
      top: 23%;
    }
  }
}

.job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .job-title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 8px;

    .job-logo {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 30px;
        max-width: 30px;
      }
    }
    .job-logo-mobile {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 25px;
        max-width: 25px;
      }
    }

    .title-desc {
      text-align: left;

      h3 {
        font-weight: 700;
        font-size: 17px;
        color: var(--hfont);
        font-family: "Univers EW", sans-serif;
        margin-bottom: 6px;

        @media only screen and (min-width: 1600px) {
          font-size: 19px;
        }
      }

      h5 {
        font-weight: 700;
        font-size: 15px;
        color: var(--hfont);
        font-family: "Univers EW", sans-serif;
        margin-bottom: 6px;

        @media only screen and (min-width: 1600px) {
          font-size: 17px;
        }
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #aeaeae;
        margin-bottom: 0;

        span {
          background: rgba(255, 184, 3, 0.3);
          border-radius: 3px;
          padding: 3px 3px;
          color: var(--hfont);
          display: inline-flex;
          align-items: center;

          img {
            width: 13px;
            height: 13px;
          }

          strong {
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
  }
}

hr {
  border-top-color: #d3cfcf !important;
}

.cstm-progress-bar {
  margin-bottom: 10px;

  .progress {
    height: 30px;
    border-radius: 0;
    box-shadow: 0px 6px 30px rgba(88, 91, 96, 0.1);

    &.dark_yellow {
      .progress-bar {
        background: #fca249;
      }
    }

    &.main_color {
      .progress-bar {
        background: var(--mcolor);
      }
    }

    &.green {
      .progress-bar {
        background: #00c99c;
      }
    }

    &.red {
      .progress-bar {
        background: #f15f67;
      }
    }

    &.dark_gray {
      .progress-bar {
        background: #606060;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .joblist {
    min-height: calc(100% - 300px);
  }
}
@media only screen and (max-width: 768px) {
  .joblist {
    min-height: calc(100% - 500px);
  }
}

.dropdown-menu {
  padding-block: 0 !important;

  .dropdown-item {
    &.active,
    &:active,
    &:hover {
      background-color: var(--mcolor);
      color: #fff;
    }
  }
}

.cstm-btn-group {
  .btn {
    padding: 10px 20px !important;
    border-radius: 100vmax;
    background-color: #e0eef7 !important;
    border-color: #e0eef7 !important;
    color: #282c36 !important;
    box-shadow: unset !important;

    @media only screen and (max-width: 767px) {
      padding: 6px 12px !important;
    }

    &.btn-light {
      background-color: #e0eef7 !important;
      border-color: #e0eef7 !important;
      box-shadow: unset !important;
    }
  }

  .btn-check:checked + .btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check) + .btn:active {
    color: #282c36 !important;
    background-color: var(--mcolor) !important;
    border-color: var(--mcolor) !important;
    color: #fff !important;
    box-shadow: 0px 6px 30px rgba(88, 91, 96, 0.1) !important;
  }
}

.line-height {
  line-height: 1 !important;
}

.pagination-block {
  .active > .page-link,
  .page-link.active {
    background-color: var(--mcolor);
    border-color: var(--mcolor);
  }

  .page-link {
    color: var(--mcolor);

    &:focus {
      box-shadow: none;
      color: #fff;
      background-color: var(--mcolor);
    }

    &:hover {
      color: #fff;
      background-color: var(--mcolor);
      border-color: var(--mcolor);
    }
  }

  .page-item:first-child,
  .page-item:last-child {
    .page-link:focus {
      color: var(--mcolor);
      background-color: #fff;
    }
  }
}
