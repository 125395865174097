.share-modal {
  .job-detail-block {
    background-color: transparent;
    box-shadow: unset;
    padding: 0px 0px 0px 0px !important;

    &::before {
      content: unset;
    }

    @media only screen and (max-width: 767px) {
      padding: 20px 10px;
    }

    .job-header {
      .job-title {
        .job-logo {
          height: 70px;
          width: 70px;

          @media only screen and (min-width: 1600px) {
            height: 125px;
            width: 125px;
            border-radius: 30px;
          }
          @media only screen and (max-width: 991px) {
            height: 65px;
            width: 65px;
          }
          img {
            max-height: 50px;
            max-width: 50px;

            @media only screen and (min-width: 1600px) {
              max-height: 70px;
              max-width: 70px;
            }
            @media only screen and (max-width: 991px) {
              height: 45px;
              width: 45px;
            }
          }
        }

        .title-desc {
          h3 {
            font-size: 24px;

            @media only screen and (min-width: 1600px) {
              font-size: 33px;
            }
          }

          ul {
            li {
              font-size: 17px;

              @media only screen and (min-width: 1600px) {
                font-size: 23px;
              }
            }
          }
        }
      }
    }
  }

  p {
    font-size: 24px;
    color: #292929;
    margin-bottom: 0;

    @media only screen and (max-width: 1599px) {
      font-size: 22px;
    }

    @media only screen and (max-width: 991px) {
      font-size: 20px;
    }
  }
  hr {
    border-top-color: #b5d3e7 !important;
  }
}

.modal-body {
  padding: 40px 40px;
  @media only screen and (max-width: 1599px) {
    padding: 30px 30px;
  }
  @media only screen and (max-width: 575px) {
    padding: 40px 25px;
  }
}

@media only screen and (min-width: 1600px) {
  .modal-md {
    --bs-modal-width: 620px;
  }
}

.social_link {
  list-style-type: none;
  margin: 40px 0;
  padding: 10px;

  @media only screen and (max-width: 1599px) {
    margin: 30px 0;
  }

  @media only screen and (max-width: 991px) {
    margin: 20px 0;
  }

  display: flex;
  justify-content: space-between;

  .copy-clipboard {
    border: 1px solid;
  }

  li {
    padding: 15px;
    display: inline-block;
    margin: 0 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      margin: 0px !important;
      padding: 0px !important;
    }
    @media only screen and (max-width: 1599px) {
      margin: 0 15px;
      padding: 6px;
    }
    @media only screen and (max-width: 2560px) {
      margin: 0px !important;
      padding: 0px !important;
    }

    @media only screen and (max-width: 991px) {
      margin: 0 10px;
      padding: 5px;
    }

    &.active {
      border: 1px solid var(--mcolor);
    }

    .social-img {
      height: 98px;
      width: 98px;

      @media only screen and (max-width: 1599px) {
        height: 55px;
        width: 55px;
      }

      @media only screen and (max-width: 991px) {
        height: 48px;
        width: 48px;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
