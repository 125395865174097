.footer-block {
  @media only screen and (min-width: 250px) {
    margin-top: 20rem;
  }
  @media only screen and (min-width: 350px) {
    margin-top: 7rem;
  }
  @media only screen and (min-width: 390px) {
    margin-top: 3rem;
  }

  @media only screen and (min-width: 576px) {
    margin-top: 5rem;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 5rem;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 7rem;
  }

  .powered_by {
    margin-bottom: 5px;

    @media only screen and (max-width: 767px) {
      padding-bottom: 20px;
    }
    p {
      margin-bottom: 0;

      span {
        font-size: 1rem;
      }
    }

    @media only screen and (max-width: 576px) {
    }
  }
  .footer-notice {
    @media only screen and (max-width: 576px) {
    }

    p {
      font-size: 14px;
      text-align: left;
      line-height: 18px;
      margin-bottom: 0;
      max-width: 575px;
    }
  }
}
