.banner-section {

  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 1599px) {
    height: calc(90vh - 120px);
  }

  @media only screen and (max-width: 991px) {
    height: calc(90vh - 120px);
  }

  @media only screen and (max-width: 767px) {
    height: calc(90vh - 70px);
    display: block;
  }

  .banner-img {
    text-align: right;
    height: fit-content;

    @media only screen and (max-width: 767px) {
      text-align: center;
      padding: 0 14vw;
    }
    @media only screen and (max-width: 500px) {
      text-align: center;
      padding: 0;
    }
    .img-fluid {
      @media only screen and (min-width: 1200px) {
        max-width: 80%;
      }
      @media only screen and (max-width: 767px) {
        max-width: 60%;
      }
    }
  }
}

.banner-info {
  text-align: left;
  padding: 40px 0;
  @media only screen and (max-width: 767px) {
    padding: 1.5rem;
  }
  h2 {
    font-family: "Univers EW", sans-serif;
    font-size: 5.65rem;
    line-height: 1;
    color: var(--hfont);
    font-weight: bold;
    margin-bottom: 2rem;
    letter-spacing: 0.01em;
    @media only screen and (max-width: 1599px) {
    }
    @media only screen and (max-width: 1599px) {
      font-size: 3.25rem;
      margin-bottom: 1.4rem;
    }
    @media only screen and (max-width: 767px) {
      font-size: 2.85rem;
    }
    @media only screen and (max-width: 576px) {
      text-align: center;
    }
  }

  p {
    font-family: inherit;

    font-weight: 400;
    font-size: 2.12rem;
    line-height: 1.3;
    color: var(--bfont);
    margin-bottom: 55px;
    max-width: 750px;

    @media only screen and (max-width: 1599px) {
      font-size: 1.4rem;
      margin-bottom: 2.6rem;
    }
    @media only screen and (max-width: 1550px) {
      max-width: 600px;
    }
    @media only screen and (max-width: 576px) {
      text-align: center;
    }
  }
}

.explore-home-btn {
  font-size: 21px !important;
  @media only screen and (max-width: 1599px) {
    font-size: 18px !important;
    padding: 12px 26px !important;
  }
  @media only screen and (max-width: 991px) {
    font-size: 16px !important;
    padding: 11px 23px !important;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}
