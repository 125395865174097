@media only screen and (min-width: 992px) {
  .navbar {
   
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1600px) {
  .container-fluid {
    padding: 0 80px !important;
  }
  .navbar {

    margin-top: 25px;
  }
}
.navbar {
  .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
    .logo-link {
      img {
        max-width: 180px;

        @media only screen and (min-width: 1600px) {
          max-width: 240px;
        }
      }
    }
  }
  .navbar-nav {
    .nav-link {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: var(--hfont);
      padding-left: 15px !important;
      padding-right: 15px !important;
      text-transform: uppercase;
      font-family: "Univers Extended";
      letter-spacing: 0.4px;
      @media only screen and (max-width: 1599px) {
        font-size: 14px;
        letter-spacing: 0px;
      }
      &:hover {
        color: var(--mcolor);
      }
    }
  }
}
.btn {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}
.header-btn {
  font-size: 17px !important;
  margin-left: 18px;
  line-height: 1 !important;
}
