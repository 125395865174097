.cstm-serach-form {
  background-color: #fff;
  box-shadow: 0px 0px 30px rgb(88 91 96 / 10%);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-clear {
    left: 0;
    bottom: -30px;
  }

  @media only screen and (min-width: 992px) {
    margin-top: 25px;
  }

  @media only screen and (max-width: 991px) {
    padding-bottom: 20px;
    padding-top: 20px;
    flex-direction: column;
  }

  .search-bar {
    padding: 12px 25px 12px 25px;
    flex-grow: 1;
    display: flex;
    background-color: #fff;
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      flex-wrap: wrap;
    }
    @media only screen and (min-width: 992px) {
      border-radius: 5px 0px 0px 5px;
    }

    @media only screen and (max-width: 991px) {
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .search-width-job-category {
      @media only screen and (min-width: 1024px) {
        width: 180px !important;
      }
    }
    .search-width-job-language {
      @media only screen and (min-width: 1024px) {
        width: 250px !important;
      }
    }
    .search-width-job-policy {
      @media only screen and (min-width: 1024px) {
        width: 320px !important;
      }
    }
    .search-input {
      position: relative;
      min-width: 135px;
      text-align: left;
      height: 100%;
      padding-right: 15px;
      padding-left: 15px;
      min-height: 40px;

      @media only screen and (min-width: 1600px) {
      }

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 32%;
        border: 1px solid #e7e7e7;
        margin-right: 1.3%;
        border-radius: 5px;
        margin-bottom: 5px;
        min-width: auto;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: calc(50% - 8px);
        flex-basis: calc(50% - 8px);
        min-width: auto;
      }

      @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 5px 10px;
      }

      &:not(:last-child) {
        &::before {
          content: '';
          position: absolute;
          height: 24px;
          width: 2px;
          background-color: #f5f2f2;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);

          @media only screen and (max-width: 1199px) {
            content: unset;
          }
        }
      }

      .input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        @media only screen and (max-width: 1199px) {
          left: 5px;
        }
        img {
          @media only screen and (max-width: 1199px) {
            width: 20px;
            height: 20px;
          }
        }

        @media only screen and (max-width: 991px) {
          left: 12px;
        }
      }
      &.with-icon {
        .form-control {
          padding-left: 40px;
        }
      }
      .form-control {
        border: unset;
        padding: 0px 15px 0px 15px;
        font-size: 18px;
        color: var(--hfont);
        font-family: inherit !important;

        text-align: left;
        height: 40px;
        border-radius: 0;

        @media only screen and (min-width: 1600px) {
          font-size: 20px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          font-size: 14px;
        }

        &::placeholder {
          font-family: inherit !important;
          color: #cccccc;
          font-size: 18px;
          @media only screen and (min-width: 1600px) {
            font-size: 20px;
          }

          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 14px;
          }
        }

        &:focus {
          box-shadow: unset;
        }
      }
    }
  }

  .search-btn {
    height: 66px;
    width: 130px;
    border-radius: 0px 10px 10px 0px;
    font-size: 18px !important;
    line-height: 1;
    @media only screen and (min-width: 1600px) {
      font-size: 20px !important;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      margin: 10px;
      height: 46px;
      border-radius: 10px;
    }

    @media only screen and (max-width: 991px) {
      height: 42px;
      line-height: 1;
      border-radius: 5px;
      font-size: 15px !important;
      padding: 0 !important;
    }
  }
}

.ps-40 {
  padding-left: 40px;

  @media only screen and (max-width: 991px) {
    padding-left: 0px;
  }
}

.lang-label {
  font-size: 19px;
  color: var(--hfont) !important;
  font-family: 'Univers Condensed';

  text-align: left;
  margin-bottom: 0;

  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
  }
}

.language-toggel-switch {
  input[type='checkbox'] {
    display: none;
  }

  #switch-button {
    position: relative;
    display: block;
    width: 58px;
    height: 30px;
    background-color: #fff;
    border-radius: 100vmax;
    cursor: pointer;

    border: 1px solid var(--mcolor);
    overflow: hidden;
  }

  #switch-button-toogel {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/lang_switch_bg.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    top: 2px;
    left: -29px;
    border-radius: 290px;
    transition: 0.4s ease left, 0.4s ease background-position;
    z-index: 2;
  }

  #eng_lan,
  #jp_lan {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--mcolor);
    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    z-index: 1;
    margin-left: 5px;
  }

  #jp_lan {
    margin-left: 33px;
  }

  #lol-checkbox:checked + #switch-button #switch-button-toogel {
    left: 29px;
  }
}
.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.react-select__placeholder {
  color: var(--hfont) !important;
}
.react-select__control {
  border: unset !important;

  &.react-select__control--is-focused {
    box-shadow: unset !important;
  }
}

.react-select__input-container {
  color: var(--hfont) !important;
}
.react-select__single-value {
  color: var(--hfont) !important;
}

.react-select__value-container {
  font-size: 18px;
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu {
  .react-select__option {
    cursor: pointer;
    &.react-select__option--is-selected {
      background-color: var(--mcolor);
    }

    &:not(.react-select__option--is-selected) {
      &.react-select__option--is-focused {
        background-color: var(--mcolor-light);
      }
    }
  }
}
.css-tlfecz-indicatorContainer {
  color: var(--hfont) !important;
}
@media only screen and (min-width: 1200px) {
  .flex-grow-xl-1 {
    flex-grow: 1 !important;
  }
}

.lang-btn,
.lang-btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: var(--hfont) !important;
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  font-family: 'Univers Condensed' !important;
  padding: 0px !important;
  @media only screen and (min-width: 1600px) {
    margin-top: 5px !important;
    font-size: 1.25rem !important;
  }
}

.popover-arrow {
  display: none !important;
}

.popover-body {
  width: 120px;
  color: var(--hfont) !important;
  font-size: 16px !important;
  font-family: 'Univers Condensed' !important;
  padding: 10px !important;
}
