.job-details-row {
  margin-top: 20px;
}

.job-detail-block::-webkit-scrollbar {
  width: 4px;
}

.job-list-wrapper::-webkit-scrollbar {
  width: 0px;
}

.job-detail-block::-webkit-scrollbar-track {
  margin: 20px 0;
}

.job-detail-block::-webkit-scrollbar-thumb,
.job-list-wrapper::-webkit-scrollbar-thumb {
  background-color: #d2cdf3;

  border-radius: 20px;
}

@media only screen and (min-width: 992px) {
  .job-list-wrapper,
  .job-detail-block {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
  }
}

.job-detail-block-spacer {
  position: relative;

  &::before {
    @media only screen and (min-width: 992px) {
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      top: 0;
      left: calc(50% - 7px) !important;
      background-color: #dadada;
      z-index: 1;
    }
  }
}

.job-detail-block {
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(88, 91, 96, 0.1);
  border-radius: 20px;
  padding: 14px;
  position: relative;

  @media only screen and (max-width: 767px) {
    padding: 20px 10px;
  }
  .jobdetail-header {
    flex-direction: column;
    height: 100%;
    align-items: start;
  }

  .job-header {
    .job-title {
      display: block;
      text-align: center;
      position: relative;

      .fav-detail-page {
        right: 5px;
      }

      .job-logo-detail {
        margin: 0 auto;
        margin-bottom: 8px;
        height: 70px;
        width: 70px;

        img {
          max-width: 50px;
          max-height: 50px;
        }
      }

      .title-desc {
        text-align: center;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 10px;

          li {
            font-size: 15px;
            font-weight: 600;
            padding: 0 8px;
            font-family: inherit !important;
            position: relative;
            color: var(--hfont);

            &:not(:first-child) {
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: var(--bfont);
              }
            }

            @media only screen and (max-width: 1199px) {
              font-size: 13px;
            }
          }
        }
      }
      .recruiter-btn {
        width: 100%;

        @media only screen and (max-width: 575px) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.detail-chip {
  font-family: inherit !important;

  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #585858;
  padding: 6px 8px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(88, 91, 96, 0.13);
  border-radius: 10px;

  @media only screen and (max-width: 1599px) {
    font-size: 13px;
  }
}

.company-ditails {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(88, 91, 96, 0.1);
  border-radius: 10px;
  padding: 15px 10px;
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 767px) {
    gap: 10px;
    padding: 10px;
  }

  .company-details-block {
    text-align: left;
    position: relative;

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: -50%;
        transform: translateY(-50%);
        height: 24px;
        width: 1px;
        background-color: #e5e3f2;

        @media only screen and (max-width: 1599px) {
          right: -12px;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #696969;
      margin-bottom: 5px;
    }

    h3 {
      font-family: "Univers EW", sans-serif;
      font-weight: 700;
      font-size: 17px;
      color: var(--mcolor);
      margin-bottom: 0;
    }
  }
}

.rating-block {
  .company-ditails {
    justify-content: space-around;
    gap: 0;
    box-shadow: unset;

    .company-details-block {
      text-align: center;
      width: 50%;

      &:not(:last-child) {
        &::before {
          right: 0;
        }
      }

      h4 {
        text-transform: uppercase;

        font-weight: 400;
        font-size: 12px;
        color: var(--hfont);
        font-family: inherit !important;
      }
    }
  }
}

.job-card {
  .card-title {
    font-family: "Univers EW", sans-serif;
    color: var(--hfont);
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 10px;
    span {
      font-size: 20px;
    }
  }

  .recent-article {
    a:hover {
      color: var(--mcolor) !important;
    }
  }

  .card-desc {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    color: var(--hfont);
    text-align: left;
  }
  .job-description {
    margin-bottom: 15px;
    li {
      font-size: 14px;
    }
    p {
      font-weight: 400;

      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      overflow: hidden;

      margin-bottom: 0;

      @media only screen and (min-width: 1600px) {
        font-size: 15px;
      }
    }
    .read-more-less {
      color: var(--mcolor);
      text-transform: capitalize;
      @media only screen and (min-width: 1600px) {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}

.teck_skill-block {
  max-width: 24rem;
  word-wrap: break-word;
  @media (max-width: 768px) {
    max-width: 18rem;
  }

  @media (max-width: 576px) {
    max-width: 18rem;
  }
  .detail-chip {
    padding: 6px 15px;
    border-radius: 100vmax;
    text-transform: uppercase;
  }
}

ul {
  li {
    &::marker {
      font-size: 20px;
    }
  }
}

.marker-list {
  list-style-type: none;

  li {
    position: relative;
    padding-bottom: 10px;

    padding-left: 15px;
    list-style: none;

    @media only screen and (min-width: 1600px) {
      font-size: 15px;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:before {
      content: "●";
      position: absolute;
      left: 0;
      top: 0;

      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
  }

  &.dot-list {
    li {
      &:before {
        background-image: unset;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background-color: var(--mcolor);
      }
    }
  }
}
.marker-list-number {
  list-style-type: none;

  li {
    position: relative;
    padding-bottom: 10px;

    padding-left: 15px;
    list-style: none;

    @media only screen and (min-width: 1600px) {
      font-size: 15px;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
  }

  &.dot-list {
    li {
      &:before {
        background-image: unset;
        height: 9px;
        width: 9px;
        border-radius: 50%;
        background-color: var(--mcolor);
      }
    }
  }
}
.detail-text {
  font-family: sans-serif;
  letter-spacing: 0;
  color: #263b5e;
  line-height: 25px;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}

.cstm-label {
  font-size: 1rem !important;

  @media only screen and (min-width: 1600px) {
    font-size: 1.25rem !important;
  }
}

.progress-bar-label {
  font-size: 14px;
  color: #282c36;

  @media only screen and (min-width: 1600px) {
    font-size: 1rem !important;
  }
}

.customer-review-section {
  margin-top: 15px;

  ul {
    list-style-type: none;

    li {
      padding: 10px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #d3cfcf;
      }

      &:first-child {
        padding-top: 0;
      }

      .review-date {
        font-size: 10px;
        line-height: 16px;
        color: #818181;
      }

      .review-content {
        h3 {
          font-weight: 700;
          font-size: 14px;
          line-height: 25px;
          color: #282c36;
          margin-bottom: 8px;
        }

        .review-desc {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #878787;
          margin-bottom: 0;
        }
      }
    }
  }
}

.salaryArkstrik-btn {
  border: none;
  background: none;
  font-size: 22px;
  padding: 0 2px;
}

.sharebtn {
  padding: 7px;
  border: 1px solid rgba(77, 77, 77, 0.4);
  display: inline-flex;
  align-items: center;
  border-radius: 10px;

  right: 5px;
  top: 5px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: var(--mcolor);

    svg {
      path {
        fill: #fff;
      }
    }
  }
}
