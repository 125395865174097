.contactus-block {
  background: #ffffff;
  box-shadow: 0px 6px 30px rgb(88 91 96 / 10%);
  border-radius: 20px;
  padding: 20px;
  margin: 0 30px;

  h2 {
    color: var(--mcolor);
    text-align: center;
  }
  h5 {
    font-family: "Univers EW", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5rem;
    @media only screen and (min-width: 1600px) {
      font-size: 20px;
      line-height: 2rem;
    }
  }
}
