.label-list {
  display: flex;
  color: var(--hfont);
  font-weight: 600;
  font-size: 18px;
  gap: 10px;
  align-items: center;
  font-family: Univers EW, sans-serif;

  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 420px) {
    justify-content: center;
  }

  span {
    color: var(--hfont);
    font-weight: bold;
  }

  .dropdown {
    .btn-primary {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--hfont);
      box-shadow: unset;
      font-size: 16px !important;
      font-weight: 600;
      padding: 0 !important;

      @media only screen and (min-width: 1600px) {
        font-size: 18px !important;
      }
    }
  }
}
.copy-search {
  font-size: 12px !important;
}
.page-title {
  color: var(--mcolor);
  text-align: center;
}

.select-catagory-block {
  position: sticky;
  top: 24px;
  // @media only screen and (max-width: 767px) {
  //   display: none;
  // }
  h4 {
    font-size: 18px;
    font-weight: 600;
    font-family: "Univers EW", sans-serif;
    color: var(--hfont);

    @media only screen and (min-width: 1600px) {
      font-size: 20px;
    }
  }
}
.select-catagory {
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 40px;

    @media only screen and (max-width: 1199px) {
      margin-bottom: 20px;
    }
  }

  .company-search {
    .comp-search-dropdown {
      max-height: 200px;
      overflow-y: auto;
    }
  }
  .category-block {
    span {
      line-height: initial;
      cursor: pointer;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    font-family: "Univers EW", sans-serif;
    color: var(--hfont);

    @media only screen and (min-width: 1600px) {
      font-size: 18px;
    }
  }

  .checkbox-list {
    .list-group {
      border: unset;

      .list-group-item {
        padding: 0;
        background-color: transparent;
        border: unset;

        .form-check-label {
          font-family: inherit !important;
          font-size: 14px;
          line-height: 20px;
          color: var(--bfont);

          @media only screen and (min-width: 1600px) {
            font-size: 20px;
          }
        }
      }
    }

    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    .accordion-button:not(.collapsed) {
      color: #fff;
      background-color: var(--mcolor);
    }
    .accordion-button {
      padding: 10px;
    }

    .accordion-button:focus {
      box-shadow: none;
    }
  }
}

.checkbox-primary .form-check-input:checked {
  background-color: var(--mcolor);
  border-color: var(--mcolor);
}
.form-check-input:focus {
  box-shadow: unset;
}

.checkbox-primary .form-check {
  margin-bottom: 0.5rem;
}

.job-list-block {
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(88, 91, 96, 0.1);
  border-radius: 20px;
  padding: 20px;

  &.active {
    border: 2px solid var(--mcolor);
    box-shadow: 0px 5px 20px rgb(0 0 0 / 25%);
  }

  .job-content {
    display: flex;
    gap: 50px;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 1599px) {
      gap: 30px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }

    .icon-box {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      &:not(:last-child) {
        &::before {
          content: "";
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
          height: 30px;
          width: 1px;
          background-color: #e5e3f2;

          @media only screen and (max-width: 1599px) {
            right: -15px;
          }

          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            content: unset;
          }
        }
      }

      img {
        width: 15px;
        height: 15px;
      }

      .icon-desc {
        h4 {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #727272;
          font-family: inherit !important;
          margin-bottom: 5px;

          span {
            font-size: 22px;
          }
          .salaryArkstrik-btn {
            border: none;
            background: none;
            font-size: 22px;
            padding: 0 2px;
          }

          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }

        h3 {
          font-family: "Univers EW", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: var(--hfont);
          margin-bottom: 0;

          @media only screen and (min-width: 1600px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .job-content-new {
    gap: 50px;
    align-items: center;
    position: relative;

    @media only screen and (max-width: 1599px) {
      gap: 30px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }

    .icon-box {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;

      &:not(:last-child) {
        &::before {
          content: "";
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
          height: 30px;
          width: 1px;
          background-color: #e5e3f2;

          @media only screen and (max-width: 1599px) {
            right: -15px;
          }

          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            content: unset;
          }
        }
      }

      img {
        width: 15px;
        height: 15px;
      }

      .icon-desc {
        h4 {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #727272;
          font-family: inherit !important;
          margin-bottom: 5px;

          span {
            font-size: 22px;
          }
          .salaryArkstrik-btn {
            border: none;
            background: none;
            font-size: 22px;
            padding: 0 2px;
          }

          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }

        h3 {
          font-family: "Univers EW", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: var(--hfont);
          margin-bottom: 0;

          @media only screen and (min-width: 1600px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  .job-description {
    margin-bottom: 15px;
    p {
      font-family: inherit !important;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: var(--bfont);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      letter-spacing: 0.4px;
      margin-bottom: 0;

      @media only screen and (min-width: 1600px) {
        font-size: 18px;
      }
    }
    .read-more-less {
      color: var(--mcolor);
      text-transform: capitalize;
      @media only screen and (min-width: 1600px) {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .job-detail-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      gap: 10px;
    }
    @media only screen and (max-width: 575px) {
      gap: 10px;
    }

    .details-label {
      display: flex;
      flex-wrap: wrap;

      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      span {
        margin: 5px;
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;
        font-family: inherit !important;
        color: #8d8d8d;
        border: 0.6px solid rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 7px 10px;

        @media only screen and (min-width: 1600px) {
          font-size: 14px;
          padding: 10px 15px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .details-learn {
      display: flex;
      flex: none;
    }
  }
}
.btn.dropdown-toggle {
  box-shadow: unset !important;
  display: flex;
  align-items: center;
}

.clearall {
  font-weight: bold !important;
  color: var(--hfont);
  font-family: "Univers EW", sans-serif;
  font-size: 14px;
}

.clear {
  color: var(--hfont);
  font-family: "Univers EW", sans-serif;
  font-size: 14px;
}

.spinner-block {
  min-height: 320px;
}
